import React from 'react';
import loader from '../assets/img/loader.gif';
require('es6-promise').polyfill();
require('isomorphic-fetch');

export default class Modals extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        login: {
          email: '',
          emailFocus: false,
          password: '',
          passwordFocus: false,
          agree1: false,
          captch: '',
          captchFocus: false,
        },
        addData: {
          name: '',
          nameFocus: false,
          email: '',
          emailFocus: false,
          phone: '',
          phoneFocus: false,
          city: '',
          cityFocus: false,
          street: '',
          streetFocus: false,
          home: '',
          homeFocus: false,
          flat: '',
          flatFocus: false,
          captch: '',
          captchFocus: false,
        },
        reg: {
          name: '',
          nameFocus: false,
          surname: '',
          surnameFocus: false,
          email: '',
          emailFocus: false,
          phone: '',
          phoneFocus: false,
          numberCart: '',
          numberCartFocus: false,
          agree1: false,
          agree2: false,
          city: '',
          cityFocus: false,
          street: '',
          streetFocus: false,
          home: '',
          homeFocus: false,
          flat: '',
          flatFocus: false,
          postalIndex: '',
          postalIndexFocus: false,
          captch: '',
          captchFocus: false,
        },
        changeData: {
          name: '',
          nameFocus: false,
          email: '',
          emailFocus: false,
          phone: '',
          phoneFocus: false,
          numberCart: '',
          numberCartFocus: false,
          agree1: false,
          captch: '',
          captchFocus: false,
        },
        recovery: {
          email: '',
          emailFocus: false,
          captch: '',
          captchFocus: false,
        },
        confirm: {
          email: '',
          emailFocus: false,
        },
        change: {
          pass1: '',
          pass1Focus: false,
          pass2: '',
          pass2Focus: false,
          pass3: '',
          pass3Focus: false,
          captch: '',
          captchFocus: false,
        },
        feedback: {
          email: '',
          emailFocus: false,
          name: '',
          nameFocus: false,
          topic: null,
          topicFocus: false,
          message: '',
          messageFocus: false,
          agree: false,
          captch: '',
          captchFocus: false,
        },
        prizes: {
          prize_name: 'шоппер',
          prize_price: '150',
          prize_id: 0,
        },
      },
      errors: {
        login: {
          email: false,
          emailText: 'Введен некорректный e-mail',
          password: false,
          passwordText: 'Введен некорректный пароль',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
          agree1: false,
          agree1Text: 'Необходимо ваше согласие',
        },
        adddata: {
          name: false,
          nameText: 'Введено некорректное имя',
          // name: false,
          // nameText: 'Введено некорректно имя',
          email: false,
          emailText: 'Введен некорректный e-mail',
          phone: false,
          phoneText: 'Введен некорректный телефон',
        },
        reg: {
          name: false,
          nameText: 'Введено некорректное имя',
          name: false,
          nameText: 'Введена некорректная фамилия',
          email: false,
          emailText: 'Введен некорректный e-mail',
          phone: false,
          phoneText: 'Введен некорректный телефон',
          city: false,
          cityText: 'Введен некорректный город',
          street: false,
          streetText: 'Введена некорректная улица',
          home: false,
          homeText: 'Введен некорректный дом',
          flat: false,
          flatText: 'Введена некорректная квартира',
          postalIndex: false,
          postalIndexText: 'Введен некорректный индекс',
          numberCart: false,
          numberCartText: 'Введен некорректный номер карты',
          agree1: false,
          agree1Text: 'Необходимо ваше согласие',
          agree2: false,
          agree2Text: 'Необходимо ваше согласие',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
        recovery: {
          email: false,
          emailText: 'Введен некорректный e-mail',
        },
        confirm: {
          email: false,
          emailText: 'Введен некорректный e-mail',
        },
        change: {
          pass1: false,
          pass1Text: 'Введен некорректный пароль',
          pass2: false,
          pass2Text: 'Введен некорректный пароль',
          pass3: false,
          pass3Text: 'Введен некорректный пароль',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
          errorStatus: false,
          errorMessage: '',
        },
        feedback: {
          email: false,
          name: false,
          topic: false,
          message: false,
          nameText: 'Введено некорректное имя',
          emailText: 'Введен некорректный e-mail',
          topicText: 'Ввыберите тему сообщения',
          messageText: 'Сообщение должно быть больше 5 символов',
          agree: false,
          agreeText: 'Необходимо ваше согласие',
          captch: false,
          captchText: 'Подтвердите, что Вы не робот!',
        },
      },
      topicInput: '',
      topicDropdown: false,
      modalTitleInfo: '',
    };
  }

  componentDidMount() {
    {
      this.props.beforeTopicInput &&
        this.setState({
          topicInput: this.props.SelectTitles[0].name,
        });
    }
  }
  componentWillMount() {}

  componentWillUnmount() {}

  lkShow = () => {
    this.modalClose();
    this.setState(
      {
        modal__lk: true,
      },
      () => {
        document.body.classList.add('overflow-disabled');
      },
    );
  };

  renderError = (form, field) => {
    switch (form) {
      case 'login':
        switch (field) {
          case 'email':
            if (this.state.errors.login.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.login.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.login.emailText}
                </div>
              );
            }
            break;
          case 'password':
            if (this.state.errors.login.password === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.login.password = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.login.passwordText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.login.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.login.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.login.captchText}
                </div>
              );
            }
            break;
        }
        break;
      case 'reg':
        switch (field) {
          case 'name':
            if (this.state.errors.reg.name === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.name = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.nameText}
                </div>
              );
            }
            break;
          case 'email':
            if (this.state.errors.reg.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.emailText}
                </div>
              );
            }
            break;
          case 'phone':
            if (this.state.errors.reg.phone === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.phone = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.phoneText}
                </div>
              );
            }
            break;
          case 'city':
            if (this.state.errors.reg.city === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.city = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.cityText}
                </div>
              );
            }
            break;
          case 'street':
            if (this.state.errors.reg.street === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.street = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.streetText}
                </div>
              );
            }
            break;
          case 'home':
            if (this.state.errors.reg.home === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.home = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.homeText}
                </div>
              );
            }
            break;
          case 'flat':
            if (this.state.errors.reg.flat === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.flat = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.flatText}
                </div>
              );
            }
            break;
          case 'postalIndex':
            if (this.state.errors.reg.postalIndex === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.postalIndex = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.postalIndexText}
                </div>
              );
            }
            break;
          case 'agree1':
            if (this.state.errors.reg.agree1 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.agree1 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.agree1Text}
                </div>
              );
            }
            break;
          case 'agree2':
            if (this.state.errors.reg.agree2 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.agree2 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.agree2Text}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.reg.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.captchText}
                </div>
              );
            }
            break;
        }
        break;
      case 'addData':
        switch (field) {
          case 'name':
            if (this.state.errors.reg.name === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.name = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.nameText}
                </div>
              );
            }
            break;
          case 'email':
            if (this.state.errors.reg.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.emailText}
                </div>
              );
            }
            break;
          case 'phone':
            if (this.state.errors.reg.phone === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.phone = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.phoneText}
                </div>
              );
            }
            break;
          case 'phone':
            if (this.state.errors.reg.phone === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.phone = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.phoneText}
                </div>
              );
            }
            break;
          case 'city':
            if (this.state.errors.reg.city === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.city = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.cityText}
                </div>
              );
            }
            break;
          case 'street':
            if (this.state.errors.reg.street === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.street = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.streetText}
                </div>
              );
            }
            break;
          case 'home':
            if (this.state.errors.reg.home === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.home = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.homeText}
                </div>
              );
            }
            break;
          case 'flat':
            if (this.state.errors.reg.flat === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.flat = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.flatText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.reg.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.reg.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.reg.captchText}
                </div>
              );
            }
            break;
        }
        break;
      case 'recovery':
        switch (field) {
          case 'email':
            if (this.state.errors.recovery.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.recovery.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.recovery.emailText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.recovery.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.recovery.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.recovery.captchText}
                </div>
              );
            }
            break;
        }
        break;
      case 'confirm':
        switch (field) {
          case 'email':
            if (this.state.errors.confirm.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.confirm.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.confirm.emailText}
                </div>
              );
            }
            break;
        }
        break;
      case 'change':
        switch (field) {
          case 'pass1':
            if (this.state.errors.change.pass1 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.change.pass1 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.change.pass1Text}
                </div>
              );
            }
            break;
          case 'pass2':
            if (this.state.errors.change.pass2 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.change.pass2 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.change.pass2Text}
                </div>
              );
            }
            break;
          case 'pass3':
            if (this.state.errors.change.pass3 === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.change.pass3 = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.change.pass3Text}
                </div>
              );
            }
            break;
          case 'error':
            if (this.state.errors.change.errorStatus === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.change.errorStatus = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.change.errorMessage}
                </div>
              );
            }
            break;
        }
        break;
      case 'feedback':
        switch (field) {
          case 'name':
            if (this.state.errors.feedback.name === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.name = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.nameText}
                </div>
              );
            }
            break;
          case 'email':
            if (this.state.errors.feedback.email === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.email = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.emailText}
                </div>
              );
            }
            break;
          case 'topic':
            if (this.state.errors.feedback.topic === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.topic = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.topicText}
                </div>
              );
            }
            break;
          case 'message':
            if (this.state.errors.feedback.message === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.message = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.messageText}
                </div>
              );
            }
            break;
          case 'agree':
            if (this.state.errors.feedback.agree === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.agree = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.agreeText}
                </div>
              );
            }
            break;
          case 'captch':
            if (this.state.errors.feedback.captch === true) {
              return (
                <div
                  className="error__message"
                  onClick={() => {
                    let errors = this.state.errors;
                    errors.feedback.captch = false;
                    this.setState({ errors: errors });
                  }}>
                  {this.state.errors.feedback.captchText}
                </div>
              );
            }
            break;
        }
        break;
    }
  };

  toggleTopicDropdown = () => {
    let errors = this.state.errors;
    errors.feedback.topic = false;
    this.setState({
      topicDropdown: !this.state.topicDropdown,
      errors: errors,
    });
  };

  selectTopic = (topic, title, valId) => {
    let data = this.state.data;
    data.feedback.topic = topic;
    let errors = this.state.errors;
    errors.feedback.topic = false;
    // this.props.data.setState({
    //   network: valId,
    // });
    this.setState({
      data: data,
      errors: errors,
      topicDropdown: false,
      topicInput: title,
    });
    this.props.getWinners(1, this.props.tab, valId);
  };

  render() {
    return (
      <div
        className={
          'form__select form__item ' + (this.state.topicInput !== '' ? 'form-focused' : '')
        }>
        <div
          className={[
            'form__field form__select ',
            this.state.errors.feedback.topic === true ? 'has-error' : '',
          ].join(' ')}>
          <div className="select__custom">
            {this.props.TitleInput && (
              <div
                className="form__label"
                onClick={() => {
                  this.setState({
                    topicDropdown: true,
                  });
                }}>
                {this.props.TitleInput}
              </div>
            )}
            <div
              onClick={() => this.toggleTopicDropdown()}
              className={
                'select__custom-input d-flex align-items-center ' +
                (this.state.topicDropdown === true ? 'active' : '')
              }>
              <span dangerouslySetInnerHTML={{ __html: this.state.topicInput }}></span>
              <div
                className={
                  'select__custom-arrow d-flex align-items-center justify-content-center ' +
                  (this.state.topicDropdown === true ? 'active' : '')
                }>
                <i
                  className={
                    'select__arrow ' + (this.state.topicDropdown === true ? 'active' : '')
                  }></i>
              </div>
            </div>
            <div
              className={
                'select__custom-dropdown ' + (this.state.topicDropdown === true ? 'active' : '')
              }>
              <div className="select__custom-dropdown-list">
                {this.props.SelectTitles?.map((selTit, index) => (
                  <div
                    onClick={() => this.selectTopic(selTit.id, selTit.name, selTit.valId)}
                    className={
                      'select__custom-dropdown-item ' +
                      (this.state.data.feedback.topic === 1 ? 'active' : '')
                    }
                    key={'selectTopic-' + index}
                    dangerouslySetInnerHTML={{ __html: selTit.name }}></div>
                ))}
              </div>
            </div>
          </div>

          {this.renderError('feedback', 'topic')}
        </div>
      </div>
    );
  }
}
