import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import CustomScroll from 'react-custom-scroll';
export default function ModalView({ children, ...props }) {
  return (
    <div
      data-show={props.modalShow === props.modalData.id ? 'true' : 'false'}
      className={
        'modal__custom modal__custom-big modal__custom-reg modal__custom-' + props.modalData.id
      }>
      <div className="modal__box">
        <div
          className="modal__close d-flex align-items-center justify-content-center"
          onClick={() => {
            props.nameClose ? props.modalClose(props.nameClose) : props.modalClose(null);
          }}></div>
        <div className="modal__box-content">
          <div className="modal__box-header d-flex align-items-end justify-content-between">
            <div className="modal__box-title">{props.modalData.name}</div>
          </div>
          <div className="modal__box-content-children-box">
            <div className="modal__box-content-children">
              {/* <Scrollbars heightRelativeToParent="100%" className="cursor-normalizer"> */}
              <CustomScroll>
                <div className="modal__box-content-children-scroll">{children}</div>
                {/* </Scrollbars> */}
              </CustomScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
