import React, { Component } from 'react';
import logo from '../assets/img/logo.svg';
import logoWhite from '../assets/img/logoWhite.svg';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
require('es6-promise').polyfill();
require('isomorphic-fetch');
export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      BodyClass: '',
      tab: 0,
      cabinet: false,
    };
  }

  componentDidMount() {

  	let t = this;

  	setTimeout(function(){

  	let button1 = document.querySelector(".home .section-1 .btn-1");
    let button2 = document.querySelector(".home .section-2 .btn-1");
    let button4 = document.querySelector(".home .section-4 .btn-1");

    if (button1) {
    button1.addEventListener('click', function(){
      window.dataLayer.push({
      	"event": "NutrilakEvent",
		"eventCategory": "main",
		"eventAction": "clickButton",
		"eventLabel": "registerCheck1"
	  });

	  localStorage.setItem('regSource', 1);
	  localStorage.setItem('checkSource', 1);
	  localStorage.setItem('checkPage', 'main');
    });
    }

    if (button2) {
    button2.addEventListener('click', function(){
      window.dataLayer.push({
      	"event": "NutrilakEvent",
		"eventCategory": "main",
		"eventAction": "clickButton",
		"eventLabel": "registerCheck2"
	  });

	  localStorage.setItem('regSource', 2);
	  localStorage.setItem('checkSource', 2);
	  localStorage.setItem('checkPage', 'main');
    });
    }

	if (button4) {
    button4.addEventListener('click', function(){
      window.dataLayer.push({
      	"event": "NutrilakEvent",
		"eventCategory": "main",
		"eventAction": "clickButton",
		"eventLabel": "registerCheck3"
	  });

	  localStorage.setItem('regSource', 3);
	  localStorage.setItem('checkSource', 3);
	  localStorage.setItem('checkPage', 'main');
    });
    }

	if (t.props.profile && t.props.profile.userUuid) {

		//console.log(t.props.profile);

		window.dataLayer.push({
		  "event": "NutrilakPageview",
		  "userId": t.props.profile.userUuid
		});

	} else {
		window.dataLayer.push({
		  "event": "NutrilakPageview",
		});
	}

	}, 1000);
  }

  render() {
    return (
      <div
        className={
          'header header_min ' +
          (!this.props.directionDown && this.props.scroll > 5 ? 'top ' : '') +
          (this.props.nav === true ? 'active' : '')
        }>
        <div className="container">
          <div className="logo__box d-flex align-items-center">
            <a href="/" className="logo">
              <img src={this.props.nav ? logoWhite : logo} className="nav-logo" alt="Logo" />
            </a>
          </div>
          <div className="header__btn">
            <div className={'hamburger ' + (this.props.nav ? ' -active' : '')}>
              <div
                className="hamburger-box"
                onClick={() => {
                  this.props.clickMenu();
                }}>
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </div>
          <div className={'header__right' + (this.props.nav ? ' active' : '')}>
            <div className="container-m">
              <ul id="menu">
                {this.props.menu?.map((mn, index) => (
                  <li key={mn.link + '_' + index}>
                    <a
                      href={mn.link}
                      onClick={() => {
                        this.props.clickMenuClose();
                      }}>
                      {mn.name}
                    </a>
                  </li>
                ))}
              </ul>
              {localStorage.getItem('auth_key') ? (
                <a href="/cabinet" className="btn btn-2 btn-sm">
                  Личный кабинет
                </a>
              ) : (
                <button
                  className="btn btn-2 btn-sm"
                  onClick={() => {
                  	window.dataLayer.push({
				      	"event": "NutrilakEvent",
						"eventCategory": "main",
						"eventAction": "clickButton",
						"eventLabel": "lk"
				    });
                  	this.props.runModalShow(1, 'registr');
                  }}>
                  Личный кабинет
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
