import React from 'react';
import CustomScroll from 'react-custom-scroll';
import ReactPaginate from 'react-paginate';
import Select from './Select';

require('es6-promise').polyfill();
require('isomorphic-fetch');

let sl_set = {},
  sl_set2 = {},
  sl_set3 = {};
export default class Winners extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      submit: false,
      loaded: false,
      webp: true,
      loading_winners: true,
      tab: 0,
      emailPlaceholder: 'Найти себя по e-mail',
      network: 1,
      winners: [],
      email: '',
      emailError: false,
      emailErrorText: 'Введите корректный e-mail',
      emptyText: 'Победители еще не определены',
      page: 1,
      Offset: 0,
      data: [],
      perPage: 0,
      currentPage: 1,
      filter: [
        {
          id: 1,
          title: 'Яндекс Плюс',
          click: '1',
        },
        {
          id: 2,
          title: 'Деньги на карту',
          click: '2',
        },
        {
          id: 3,
          title: 'Путешествие',
          click: '3',
        },
      ],
      SelectTitles: [
        {
          id: 1,
          name: '<b>1&nbsp;неделя</b>09.09.2024&nbsp;&mdash; 15.09.2024',
          valId: '1',
        },
        {
          id: 2,
          name: '<b>2&nbsp;неделя</b>16.09.2024&nbsp;&mdash; 22.09.2024',
          valId: '2',
        },
        {
          id: 3,
          name: '<b>3&nbsp;неделя</b>23.09.2024&nbsp;&mdash; 29.09.2024',
          valId: '3',
        },
        {
          id: 4,
          name: '<b>4&nbsp;неделя</b>30.09.2024&nbsp;&mdash; 06.10.2024',
          valId: '4',
        },
        {
          id: 5,
          name: '<b>5&nbsp;неделя</b>07.10.2024&nbsp;&mdash; 13.10.2024',
          valId: '5',
        },
        {
          id: 6,
          name: '<b>6&nbsp;неделя</b>14.10.2024&nbsp;&mdash; 20.10.2024',
          valId: '6',
        },
        {
          id: 7,
          name: '<b>7&nbsp;неделя</b>21.10.2024&nbsp;&mdash; 27.10.2024',
          valId: '7',
        },
        {
          id: 8,
          name: '<b>8&nbsp;неделя</b>28.10.2024&nbsp;&mdash; 03.11.2024',
          valId: '8',
        },
        {
          id: 9,
          name: '<b>9&nbsp;неделя</b>04.11.2024&nbsp;&mdash; 10.11.2024',
          valId: '9',
        },
      ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    /*document.body.classList.add('not-front');
        document.body.classList.add('page-winners');
        document.body.classList.remove('front');*/
    // врменнно скрыт
    this.getWinners(1, this.state.tab, this.state.network);
  }

  componentWillMount() {}

  componentWillUnmount() {
    this._isMounted = false;
    /*document.body.classList.remove('page-winners');
        document.body.classList.remove('not-front');*/
  }

  switchTab = (tab, tabId) => {
    this.setState({
      tab: this.state.tab === tabId ? 0 : tabId,
      loading_winners: true,
    });
    this.getWinners(1, this.state.tab === tabId ? 0 : tabId, this.state.network);

    this.setState({
      emailError: false,
      email: '',
    });
  };

  getWinners = async (page, tab, network) => {
    this.setState({
      network: network,
      tab: tab,
    });
    await fetch(this.props.API_URL + '/getWinners?type=' + tab + '&week=' + network, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        if (response !== null) {
          if (response.result === 1 || response === null) {
            this.setState({
              loading_winners: false,
              loading: false,
            });

            if (response.result !== null) {
              this.setState({
                // perPage: response.data.paging.total,
                winners: response.data.rows,
                emptyText: 'Победители еще не определены',
              });
            } else {
              this.setState({
                winners: [],
                emptyText: 'Победители еще не определены',
              });
            }
          }
        } else {
          this.setState({
            winners: [],
            emptyText: 'Победители еще не определены',
          });
        }
      });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        page: selectedPage + 1,
        offset: offset,
      },
      () => {
        this.getWinners(this.state.page, this.state.tab);
      },
    );
  };

  searchWinners = async (page, network, term) => {
    const formData = new FormData();
    formData.append('term', term);
    await fetch(this.props.API_URL + '/getWinners?email=' + term, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.result === 1) {
          this.setState({
            loading_winners: false,
            loading: false,
            perPage: 1,
            tab: 0,
          });

          //console.log(response.data.rows.length);
          if (response.data.rows.length !== 0) {
            this.setState({
              winners: response.data.rows,
              emptyText: 'Победители еще не определены',
            });
          } else {
            //console.log('sdfsdf');
            this.setState({
              emptyText: 'Среди победителей данный аккаунт не найден',
              winners: [],
            });
          }
        }
      });
  };

  renderFilter = () => {
    return (
      <div className="filter">
        {this.state.filter.map((filter, index) => (
          <div
            className={'filter-item ' + (this.state.tab === filter.id ? 'active' : '')}
            key={'winner-tab-' + index}
            onClick={() => {
              this.switchTab(filter.click, filter.id);
            }}>
            <span className={'ico ico-filter-' + filter.id}></span>
            {filter.title}
          </div>
        ))}
      </div>
    );
  };
  renderWinners = () => {
    //console.log(this.state.winners);
    if (this.state.winners.length > 0) {
      return (
        <div>
          <div className="table__wrap">
            <div className="table__head">
              <div className="table__row d-flex align-items-start">
                <div className="tb__col tb__th">Имя победителя</div>
                <div className="tb__col tb__th">E-mail</div>
                <div className="tb__col tb__th">Дата</div>
                <div className="tb__col tb__th">Приз</div>
              </div>
            </div>
            <div className="table__body" data-count={this.state.winners.length}>
              {this.state.winners.map((winner, index) => (
                <div className="table__rows" key={'winner_row-' + index}>
                  <div
                    key={'winner_' + index.toString()}
                    className="table__row d-flex align-items-start">
                    <div className="tb__col">{winner.name}</div>
                    <div className="tb__col">{winner.email}</div>
                    <div className="tb__col">{winner?.date}</div>
                    <div className="tb__col">{winner.prize.title}</div>
                  </div>
                </div>
              ))}
            </div>
            {/* <div
              className={
                'codes-loader ' + (this.state.loading_winners === true ? 'active' : '')
              }></div> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="table__wrap">
          <div className="table__head">
            <div className="table__row d-flex align-items-start">
              <div className="tb__col tb__th">Имя победителя</div>
              <div className="tb__col tb__th">E-mail</div>
              <div className="tb__col tb__th">Дата</div>
              <div className="tb__col tb__th">Приз</div>
            </div>
          </div>
          <div className="table__body" data-count={this.state.winners.length}>
            <div className="table__scroll">
              <div className="table__rows">
                <div className="table__row d-flex align-items-start">
                  <div className="tb__col tb__col-empty max-widtch">{this.state.emptyText}</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
              className={
                'codes-loader ' + (this.state.loading_winners === true ? 'active' : '')
              }></div> */}
        </div>
      );
    }
  };

  renderError = () => {
    if (this.state.emailError === true) {
      return (
        <div
          className="error__message"
          onClick={() => {
            this.setState({ emailError: false });
          }}>
          {this.state.emailErrorText}
        </div>
      );
    }
  };

  searchWinner = () => {
    let submit_enable = true;
    if (this.state.email === '') {
      this.setState({
        emailError: true,
      });
      submit_enable = false;
    } else {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.state.email = this.state.email.trim();

      if (!re.test(this.state.email)) {
        this.setState({
          emailError: true,
        });
        submit_enable = false;
      } else {
        this.setState({
          emailError: false,
        });
      }
    }

    if (submit_enable === true) {
      this.setState({
        loading: true,
      });
      this.searchWinners(1, parseInt(this.state.tab) + 1, this.state.email);
    }
  };

  render() {
    return (
      <div className="section-container-winners">
        <div className="border-gradient section-winners-content">
          <div className="section-winners-content-header">
            {this.renderFilter()}
            <div
              className={
                'd-flex search search__field ' + (this.state.emailError === true ? 'has-error' : '')
              }>
              <input
                type="text"
                value={this.state.email}
                onFocus={() => {
                  this.setState({
                    emailError: false,
                  });
                }}
                placeholder={this.state.emailPlaceholder}
                onChange={(email) => {
                  this.setState({ email: email.target.value });
                }}
              />
              {this.renderError()}
              <button
                onClick={() => {
                  this.searchWinner();
                }}
                className="search__btn"></button>
            </div>
          </div>
          <div
            className={
              'section-content tab__pane tab__pane-billa fadeIn animated active ' +
              (this.state.perPage < 1 && 'noWin')
            }>
            <Select
              TitleInput={''}
              beforeTopicInput={'ЯНДЕКС.МУЗЫКА'}
              SelectTitles={this.state.SelectTitles}
              getWinners={this.getWinners}
              tab={this.state.tab}
              network={this.state.network}
            />
            {this.renderWinners()}
            {this.state.perPage > 1 ? (
              <ReactPaginate
                breakLabel={'...'}
                breakClassName={'break-me'}
                marginPagesDisplayed={1}
                pageCount={this.state.perPage}
                pageRangeDisplayed={1}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                previousLabel={'‹'}
                nextLabel={'›'}
                activeClassName={'active'}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}
