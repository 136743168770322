import React from 'react';
import CustomScroll from 'react-custom-scroll';

// require('es6-promise').polyfill();
// require('isomorphic-fetch');

export default class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: 2,
      faqList: [
        {
          id: 1,
          title: 'Кто может участвовать в розыгрыше?',
          text: 'Все дееспособные граждане РФ, достигшие 18 лет, купившие сухие смеси Нутрилак в&nbsp;одной из&nbsp;следующих сетей или <nobr>интернет-магазинов</nobr>: Детском мире, Озоне, Вайлдбериз, Магнит, Лента, Пятерочка или Гиперглобус.',
        },
        {
          id: 2,
          title: 'Когда нужно купить продукты, чтобы участвовать в акции?',
          text: 'с&nbsp;09 сентября по&nbsp;10 ноября 2024&nbsp;г.',
        },
        {
          id: 3,
          title: 'Какой товар необходимо купить?',
          text: 'В&nbsp;акции принимают участие <a href="https://promo.nutrilak.com/products.pdf" target="_blank">следующие товары</a>',
        },
        {
          id: 4,
          title: 'Сколько товаров необходимо купить?',
          text: 'Необходимо купить единоразово минимум 2 любых товара Nutrilak из&nbsp;<a href="https://promo.nutrilak.com/products.pdf" target="_blank">списка продуктов</a>',
        },
        {
          id: 5,
          title: 'Имеет ли значение из какого я города?',
          text: 'Нет, не&nbsp;имеет. В&nbsp;акции могут принять участие все граждане РФ&nbsp;достигшие 18 лет.',
        },
        {
          id: 6,
          title: 'Имеет ли значение куплен товар в розничном магазине или в интернет-магазине?',
          text: 'Нет, не&nbsp;имеет. Товар можно преобрести в&nbsp;розничном магазине и&nbsp;в&nbsp;<nobr>интернет-магазине</nobr> любой сети из&nbsp;<a href="https://promo.nutrilak.com/shops.pdf" target="_blank">списка сетей</a>',
        },
        {
          id: 7,
          title: 'Сколько призов может получить участник?',
          text: 'Один Участник может получить не&nbsp;более одного приза в&nbsp;каждой категории Призового фонда, за&nbsp;исключением поощрительных призов. Поощрительные призы не&nbsp;имеют ограничений по&nbsp;количеству выигрышей на&nbsp;одного участника.',
        },
        {
          id: 8,
          title: 'Как я смогу узнать результаты?',
          text: 'Результаты публикуются на&nbsp;странице акции на&nbsp;еженедельной основе начиная с&nbsp;18 сентября 2024 по&nbsp;14 ноября 2024 г.',
        },
        {
          id: 9,
          title: 'Как я получу приз в случае выигрыша?',
          text: 'Вручение поощрительных призов происходит путем отправки ссылки на&nbsp;электронный сертификат на&nbsp;электронную почту победителя.<br><br>Вручение еженедельных призов происходит путем перечисления денежных средств на&nbsp;банковскую карту победителя.<br><br>Вручение Главного приза происходит путем отправки ссылки на&nbsp;электронный сертификат по&nbsp;электронной почте победителя.',
        }
      ],
    };
  }

  componentDidMount() {}
  toggleFaq = (numb) => {
    if (this.state.faq === numb) {
      this.setState({
        faq: 0,
      });
    } else {
      this.setState({
        faq: numb,
      });
    }
  };

  render() {
    return (
      <div className="faq">
        <div className="faq__list">
          {this.state.faqList?.map((fq, index) => (
            <div
              key={index}
              onClick={() => {
                this.toggleFaq(fq.id);
              }}
              className={'faq__item ' + (this.state.faq === fq.id ? 'active' : '')}>
              <div
                className="faq__item-header"
                dangerouslySetInnerHTML={{ __html: fq.title }}></div>
              <div className={'faq__item-answer fadeIn animated'}>
                <div
                  className="faq__item-text "
                  dangerouslySetInnerHTML={{ __html: fq.text }}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
