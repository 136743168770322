import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Cabinet from './Cabinet';
import ModalsBlock from './Modals';
import Header from './Header';
import Footer from './Footer';
import '../assets/css/App.scss';
require('isomorphic-fetch');
const agree = "document.getElementById('agree').click();";

const link = { pravila: 'ПА_Освежись с Orbit.pdf', agreement: '' };

const API_URL = 'https://promo.nutrilak.com/backend/api';
// let submit_enable = false;
export default function Main() {
  const [roomes, setRoomes] = useState([]);
  const [roomesH, setRoomesH] = useState([]);
  const [profile, setProfile] = useState([]);
  const [modalShow, setModalShow] = useState(null);
  const [nav, setNav] = useState(false);
  const [errProduct, setErrProduct] = useState('');
  const [waitingTime, setWaitingTime] = useState(0);
  const [waitingTimeS, setWaitingTimeS] = useState(0);
  const [menu, setMenu] = useState([
    { id: 1, name: 'Главная', link: '/#main', targetBlank: false },
    { id: 1, name: 'Правила', link: '/#pravila', targetBlank: false },
    { id: 2, name: 'Призы', link: '/#prize', targetBlank: false },
    { id: 3, name: 'Победители', link: '/#winners', targetBlank: false },
    { id: 3, name: 'FAQ', link: '/#faq', targetBlank: false },
  ]);
  const [menu2, setMenu2] = useState([]);
  const [product, setProduct] = useState([]);
  const [step, setStep] = useState([]);
  const [prizeList, setPrizeList] = useState([]);
  const [codes, setCodes] = useState([]);
  const [perPage, setPerPage] = useState(null);
  const formInput = {
    id: 1,
    name: 'shareURL',
    nameForm: 'url',
    value: '',
    placeholder: '',
    room: '',
    type: 'text',
    validation: true,
    errorText: 'incorrect url',
    err: false,
  };
  const formInput2 = {
    feedack: [
      {
        id: 1,
        name: 'email',
        nameForm: 'email',
        value: '',
        placeholder: 'E-mail',
        room: '',
        type: 'email',
        validation: true,
        errorText: 'E-mail не заполнено',
        err: false,
      },
      {
        id: 2,
        name: 'name',
        nameForm: 'name',
        value: '',
        placeholder: 'Имя',
        room: '',
        type: 'text',
        validation: true,
        errorText: 'Имя не заполнено',
        err: false,
      },
      {
        id: 3,
        name: 'subject_id',
        nameForm: 'subject_id',
        value: 'Тема',
        valId: null,
        placeholder: 'Тема',
        room: '',
        type: 'select',
        errorText: 'Тема не выбрана',
        item: [
          {
            id: 1,
            name: 'Регистрация в акции',
          },
          {
            id: 2,
            name: 'Регистрация чека',
          },
          {
            id: 3,
            name: 'Призовой фонд',
          },
          {
            id: 4,
            name: 'Вопросы о продукте',
          },
          {
            id: 5,
            name: 'Технические вопросы',
          },
          {
            id: 6,
            name: 'Другое',
          },
        ],
        validation: true,
        err: false,
      },
      {
        id: 4,
        name: 'text',
        nameForm: 'text',
        value: '',
        placeholder: 'Сообщеине',
        room: '',
        type: 'textarea',
        validation: true,
        errorText: 'Сообщеине не заполнено',
        err: false,
      },
    ],
    registr: [
      {
        id: 3,
        name: 'email',
        nameForm: 'email',
        value: '',
        placeholder: 'E-mail',
        room: '',
        type: 'email',
        validation: true,
        errorText: 'E-mail не заполнено',
        err: false,
      },
      {
        id: 1,
        name: 'name',
        nameForm: 'name',
        value: '',
        placeholder: 'Имя',
        room: '',
        type: 'text',
        validation: true,
        errorText: 'Имя не заполнено',
        err: false,
      },
      {
        id: 5,
        name: 'password',
        nameForm: 'pass',
        value: '',
        placeholder: 'Пароль (минимум 6 символов)',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'Пароль не введен',
        err: false,
        togglePass: false,
        toggleActive: false,
      },
      {
        id: 9,
        name: 'password2',
        nameForm: 'none',
        value: '',
        placeholder: 'Повторите пароль',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'Пароли не совпадают',
        err: false,
        togglePass: false,
        toggleActive: false,
      },
      {
        id: 6,
        name: 'agree',
        nameForm: 'none',
        value: false,
        label:
          'Я согласен с ' +
          '<a href="/rules.pdf" target="_blank" onClick="' +
          agree +
          '">' +
          'правилами акции' +
          '</a>',
        placeholder: '',
        room: '',
        type: 'checkbox',
        validation: true,
        errorText: 'Нужно согласие',
        err: false,
      },
      {
        id: 7,
        name: 'agree',
        nameForm: 'agree',
        value: false,
        label:
          'Я даю согласие на обработку ' +
          '<a href="https://nutrilak.com/sites/default/pdf/politika-zaschitbi-i-obrabotki-dannbix.pdf" target="_blank" onClick="' +
          agree +
          '">' +
          'персональных данных' +
          '</a>',
        placeholder: '',
        room: '',
        type: 'checkbox',
        validation: true,
        errorText: 'Нужно согласие',
        err: false,
      },
    ],
    login: [
      {
        id: 3,
        name: 'email',
        nameForm: 'email',
        value: '',
        placeholder: 'E-mail',
        room: '',
        type: 'email',
        validation: true,
        errorText: 'E-mail не заполнено',
        err: false,
      },
      {
        id: 5,
        name: 'password',
        nameForm: 'pass',
        value: '',
        placeholder: 'Пароль',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'Пароль не введен',
        err: false,
        togglePass: false,
        toggleActive: false,
      },
      {
        id: 6,
        name: 'agree',
        nameForm: 'none',
        value: false,
        label:
          'Я даю согласие на обработку ' +
          '<a href="https://nutrilak.com/sites/default/pdf/politika-zaschitbi-i-obrabotki-dannbix.pdf" target="_blank" onClick="' +
          agree +
          '">' +
          'персональных данных' +
          '</a>',
        placeholder: '',
        room: '',
        type: 'checkbox',
        validation: true,
        errorText: 'Нужно согласие',
        err: false,
      },
    ],
    forgonLogin: [
      {
        id: 3,
        name: 'email',
        nameForm: 'email',
        value: '',
        placeholder: 'E-mail',
        room: '',
        type: 'email',
        validation: true,
        errorText: 'E-mail не заполнено',
        err: false,
      },
    ],
    newPass: [
      {
        id: 5,
        name: 'password',
        nameForm: 'oldPass',
        value: '',
        placeholder: 'Предыдущий пароль',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'Пароль не введен',
        err: false,
        togglePass: false,
        toggleActive: false,
      },
      {
        id: 6,
        name: 'password',
        nameForm: 'pass',
        value: '',
        placeholder: 'Пароль',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'Пароль не введен',
        err: false,
        togglePass: false,
        toggleActive: false,
      },
    ],
    updateProfile: [
      {
        id: 1,
        name: 'name',
        nameForm: 'name',
        value: '',
        placeholder: 'Имя',
        room: '',
        type: 'text',
        validation: true,
        errorText: 'Имя не заполнено',
        err: false,
      },
    ],
  };
  const Modals = [
    { id: 0, name: 'Обратная связь' },
    // регистрация
    { id: 1, name: 'Регистрация' },
    // Сообщеине отправлено
    { id: 2, name: 'Сообщение отправлено' },
    // выбор логин или регистрация
    { id: 3, name: 'Вход' },
    // авторизация
    { id: 4, name: 'modal.passToEmail' },
    // восстановление
    { id: 5, name: 'Восстановление пароля' },
    // Магазины
    { id: 6, name: 'Магазины, которые участвуют в акции' },
    // показ чека
    { id: 7, name: 'Загруженное фото' },
    // Список товаров
    { id: 8, name: 'Список товаров' },
    { id: 9, name: 'modal.emailToEmail' },
    { id: 10, name: 'modal.term', title: 'Политика' },
    { id: 11, name: 'Смена пароля' },
    { id: 12, name: 'Редактировать профиль' },
    { id: 13 },
  ];
  const widgetParams = {
    api: 'https://api.apmcheck.ru',
    apiKey: 'e25b20f7-d108-47d2-b507-aa3f6e2e670e',
    meta: { utm: 'demo_page' },
    // channel: 'test-widget',
    userUuid: profile?.userUuid,
    maxPhotoSizeInMiB: 10,
    styles: {
      mainButton: 'custom-main-button',
      manualQrButton: 'custom-manual-qr-button',
      uploadPhotosButton: 'custom-upload-button',
      submitManualQrButton: 'custom-submit-manual-qr-button',
      addPhotoButton: 'custom-add-photo-button',
      submitPhotosButton: 'custom-submit-photos-button',
    },
    i18n: {
      labels: {
        mainButton: 'Сканировать qr-код чека',
        manualQrButton: 'Ввести данные вручную',
        uploadPhotosButton: 'Загрузить чек',
        submitManualQrButton: 'Отправить',
        addPhotoButton: 'Загрузить фото',
        submitPhotosButton: 'Отправить',
      },
      screens: {
        scanQr: {
          header: 'Фото чека',
          subheader: '',
        },
        manualInput: {
          header: 'Ввести данные с чека вручную',
          subheader: '',
        },
        cameraNotAvailable: {
          header: 'Регистрация чека',
          subheader:
            '<b>нет доступа к камере устройства</b> <br /><br />Разрешите доступ к камере в настройках <br />или введите данные чека вручную',
        },
        qrParsed: {
          header: '<span class="ico-thankCheck"></span><br />Спасибо! <br />Ваш чек загружен',
          subheader:
            'После того, как Ваш чек пройдет модерацию,<br /> он автоматически станет участвовать<br /> в розыгрыше призов.',
        },
        uploadFiles: {
          header: 'Загрузка чека',
          subheader: 'Добавьте фото чека <br />(не более 5 частей)',
          fileTooLargeError: 'Файл больше 10 МБ. Загрузите файл меньшего размера.',
        },
        sentReceiptFailed: {
          header: 'Не удалось отправить чек!',
        },
      },
      errors: {
        '0002-0008': 'Ой, что-то пошло не так.',
      },
    },
    callbacks: {
      onReceiptSentSuccess: function (res) {

      	let checkSource = parseInt(localStorage.getItem('checkSource', 0));
	  	let checkPage = localStorage.getItem('checkPage', 'main');

	  	let eventLabel = "uploadCheck";
	  	if (checkSource > 0) {
	  		eventLabel += checkSource;
	  	}

      	window.dataLayer.push({
			"event": "NutrilakEvent",
			"eventCategory": checkPage,
			"eventAction": "submitForm",
			"eventLabel": eventLabel
		});

        // window.dataLayer.push({
        //   event: 'gaEvent',
        //   eventCategory: 'internal',
        //   eventAction: 'do',
        //   eventLabel: 'receipt_registration_success',
        // });

        setTimeout(() => {
          // document.location.reload();
          getCodes(1);
        }, 500);
      },
      onReceiptSentError: function (res) {
        // window.dataLayer.push({
        //   event: 'gaEvent',
        //   eventCategory: 'internal',
        //   eventAction: 'do',
        //   eventLabel: 'left_receipt_registration',
        // });
      },
    },
  };
  // const changeLang = () => {
  //   setTimeout(() => {
  //     setMenu([
  //       { id: 1, name: 'menuTop.1', link: '/#product', targetBlank: false },
  //       { id: 1, name: 'menuTop.2', link: '/#scoreboard', targetBlank: false },
  //       { id: 2, name: 'menuTop.3', link: '/#prize', targetBlank: false },
  //       { id: 3, name: 'menuTop.4', link: '/#join', targetBlank: false },
  //       { id: 3, name: 'menuTop.5', link: '/upload-photo', targetBlank: false },
  //     ]);
  //     setMenu2([
  //       {
  //         id: 1,
  //         name: 'menu2',
  //         link: 'mailto:support@activia14daychallenge.com ',
  //         targetBlank: false,
  //       },
  //     ]);
  //     setProduct([
  //       { id: 1, title: 'product.1.title', text: 'product.1.text' },
  //       { id: 2, title: 'product.2.title', text: 'product.2.text' },
  //       { id: 3, title: 'product.3.title', text: 'product.3.text' },
  //       { id: 4, title: 'product.4.title', text: 'product.4.text' },
  //       { id: 5, title: 'product.5.title', text: 'product.5.text' },
  //       { id: 6, title: 'product.6.title', text: 'product.6.text' },
  //       { id: 7, title: 'product.7.title', text: 'product.7.text' },
  //       { id: 8, title: 'product.8.title', text: 'product.8.text' },
  //       { id: 9, title: 'product.9.title', text: 'product.9.text' },
  //       { id: 10, title: 'product.10.title', text: 'product.10.text' },
  //       { id: 11, title: 'product.11.title', text: 'product.11.text' },
  //       { id: 12, title: 'product.12.title', text: 'product.12.text' },
  //       { id: 13, title: 'product.13.title', text: 'product.13.text' },
  //       { id: 14, title: 'product.14.title', text: 'product.14.text' },
  //       { id: 15, title: 'product.15.title', text: 'product.15.text' },
  //       { id: 16, title: 'product.16.title', text: 'product.16.text' },
  //       { id: 17, title: 'product.17.title', text: 'product.17.text' },
  //       { id: 18, title: 'product.18.title', text: 'product.18.text' },
  //       { id: 19, title: 'product.19.title', text: 'product.19.text' },
  //       { id: 20, title: 'product.20.title', text: 'product.20.text' },
  //       { id: 21, title: 'product.21.title', text: 'product.21.text' },
  //       { id: 22, title: 'product.22.title', text: 'product.22.text' },
  //       { id: 23, title: 'product.23.title', text: 'product.23.text' },
  //       { id: 24, title: 'product.24.title', text: 'product.24.text' },
  //       { id: 25, title: 'product.25.title', text: 'product.25.text' },
  //       { id: 26, title: 'product.26.title', text: 'product.26.text' },
  //       { id: 27, title: 'product.27.title', text: 'product.27.text' },
  //     ]);
  //     setStep([
  //       { id: 1, text: 'step.1' },
  //       {
  //         id: 2,
  //         text: 'step.2',
  //       },
  //       {
  //         id: 3,
  //         text: 'step.3',
  //       },
  //     ]);
  //   }, 30);
  // };
  useEffect(() => {
    // widgetParams.userUuid = response.data.profile.userUuid;
    // window.qrWidget.init('apm-widget', widgetParams);
    // setTimeout(() => {
    //   document.getElementById('apm-scan-qr').click();
    // }, 300);
    // runModalShow(12, 'updateProfile');
    // runModalShow(8);
    // runModalShow(6);
    // runModalShow(2);
    localStorage.getItem('auth_key') && getProfile();
    // // console.log(formInput2['registr']);
    // setProfile(JSON.parse(localStorage.getItem('profile')));
    // setAvatar(JSON.parse(localStorage.getItem('avatar')));
    // changeLang();
    // setRoomesH([formInput]);
  }, []);
  const setValidation = (error, is) => {
    setRoomes((room) =>
      room?.map((list, index) => (index === is ? { ...list, err: error } : list)),
    );
  };
  const validation = (type, value, id, name, passVal) => {
    let submit_enable = false;
    switch (type) {
      case 'email':
        if (value === '') {
          setValidation(true, id);
          submit_enable = true;
        } else {
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(value)) {
            setValidation(true, id);
          } else {
            setValidation(false, id);
          }
        }
        break;
      case 'checkbox':
        if (value === false) {
          setValidation(true, id);
          submit_enable = true;
        } else {
          setValidation(false, id);
        }
        break;
      case 'select':
        if (value === '') {
          setValidation(true, id);
          submit_enable = true;
        } else {
          setValidation(false, id);
        }
        break;
      case 'password':
        // console.log(name);
        // console.log(value);
        if (name === 'password') {
          if (value === '') {
            setValidation(true, id);
            submit_enable = true;
          } else {
            setValidation(false, id);
          }
        } else {
          if (value !== passVal) {
            setValidation(true, id);
            submit_enable = true;
          } else {
            setValidation(false, id);
          }
        }
        break;
      default:
        if (value === '') {
          setValidation(true, id);
          submit_enable = true;
        } else {
          setValidation(false, id);
        }
        break;
    }
    return submit_enable;
  };
  const modalClose = (nameClose) => {
    switch (nameClose) {
      case 'terms':
        // runModalShow(2, 'registr');
        runModalShow(1);
        break;

      default:
        setModalShow(null);
        // console.log(modalShow);
        document.body.classList.remove('overflow-disabled');
        break;
    }
  };
  const handleroom = (e, t, id, vId) => {
    switch (t) {
      case 'select':
        setRoomes((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e, valId: vId } : list)),
        );

        break;
      case 'checkbox':
        setRoomes((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;
      case 'recaptcha':
        setRoomes((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;

      default:
        const { value } = e.target;
        setRoomes((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: value } : list)),
        );
        break;
    }
  };
  // form page
  const setValidationH = (error, is) => {
    setRoomesH((room) =>
      room?.map((list, index) => (index === is ? { ...list, err: error } : list)),
    );
  };
  const validationH = (type, value, id, name, passVal) => {
    let submit_enable = false;
    switch (type) {
      case 'email':
        if (value === '') {
          setValidationH(true, id);
          submit_enable = true;
        } else {
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(value)) {
            setValidationH(true, id);
          } else {
            setValidationH(false, id);
          }
        }
        break;
      case 'checkbox':
        if (value === false) {
          setValidationH(true, id);
          submit_enable = true;
        } else {
          setValidationH(false, id);
        }
        break;
      case 'select':
        if (value === '') {
          setValidationH(true, id);
          submit_enable = true;
        } else {
          setValidationH(false, id);
        }
        break;
      case 'password':
        // console.log(name);
        // console.log(value);
        if (name === 'password') {
          if (value === '') {
            setValidationH(true, id);
            submit_enable = true;
          } else {
            setValidationH(false, id);
          }
        } else {
          if (value !== passVal) {
            setValidationH(true, id);
            submit_enable = true;
          } else {
            setValidationH(false, id);
          }
        }
        break;
      default:
        if (value === '') {
          setValidationH(true, id);
          submit_enable = true;
        } else {
          setValidationH(false, id);
        }
        break;
    }
    return submit_enable;
  };
  const errValidH = (res) => {
    if (res.text !== undefined) {
      setRoomesH((room) =>
        room?.map((list, index) =>
          list.name === 'text' ? { ...list, errorText: res.text[0], err: true } : list,
        ),
      );
    }
    if (res.url !== undefined) {
      setRoomesH((room) =>
        room?.map((list, index) =>
          list.name === 'shareURL' ? { ...list, errorText: res.url[0], err: true } : list,
        ),
      );
    }
  };
  const handleroomH = (e, t, id, vId) => {
    switch (t) {
      case 'select':
        setRoomesH((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e, valId: vId } : list)),
        );

        break;
      case 'checkbox':
        setRoomesH((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;
      case 'recaptcha':
        setRoomesH((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;

      default:
        const { value } = e.target;
        setRoomesH((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: value } : list)),
        );
        break;
    }
  };
  const runModalShow = (id, mass) => {
    setModalShow(id);
    document.body.classList.add('overflow-disabled');
    mass && mass !== '' ? setRoomes([...formInput2[mass]]) : setRoomes([]);
  };
  const errValid = (res) => {
    if (res.name !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'name' ? { ...list, errorText: res.name[0], err: true } : list,
        ),
      );
    }
    if (res.text !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'text' ? { ...list, errorText: res.text[0], err: true } : list,
        ),
      );
    }
    if (res.password !== undefined) {
      // console.log(res.password[0]);
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'password' ? { ...list, errorText: res.password[0], err: true } : list,
        ),
      );
    }
    if (res.pass !== undefined) {
      // console.log(res.password[0]);
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'password' ? { ...list, errorText: res.pass[0], err: true } : list,
        ),
      );
    }
    if (res.oldPass !== undefined) {
      // console.log(res.password[0]);
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'password' ? { ...list, errorText: res.oldPass[0], err: true } : list,
        ),
      );
    }
    if (res.accept1 !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'accept1' ? { ...list, errorText: res.accept1[0], err: true } : list,
        ),
      );
    }
    if (res.email !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'email' ? { ...list, errorText: res.email[0], err: true } : list,
        ),
      );
    }
    if (res.login !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'email' ? { ...list, errorText: res.login[0], err: true } : list,
        ),
      );
    }
    if (res.subject_id !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'subject_id' ? { ...list, errorText: res.subject_id[0], err: true } : list,
        ),
      );
    }
    if (res.region_id !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'region_id' ? { ...list, errorText: res.region_id[0], err: true } : list,
        ),
      );
    }
    if (res.recaptcha !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'recaptcha' ? { ...list, errorText: res.recaptcha[0], err: true } : list,
        ),
      );
    }
  };
  const togglePass = (is, type) => {
    // console.log(is);
    // console.log(roomes);
    setRoomes((room) =>
      room?.map((list, index) =>
        index === is
          ? {
              ...list,
              type: type === 'password' ? 'text' : 'password',
              toggleActive: !list.toggleActive,
            }
          : list,
      ),
    );
  };
  const submit = (formName, subData, subData2) => {
    let subActive = false;
    const formData = new FormData();
    switch (formName) {
      case 'feedack':
        roomes?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validation(inpItem.type, inpItem.value, index) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                inpItem.nameForm !== 'none' && formData.append(inpItem.name, 1);
                break;
              case 'select':
                inpItem.nameForm !== 'none' && formData.append(inpItem.name, inpItem.valId);
                break;
              default:
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });

          window.dataLayer.push({
			"event": "NutrilakEvent",
			"eventCategory": "main",
			"eventAction": "submitForm",
			"eventLabel": "feedback"
 		  });

          fetch(API_URL + '/sendFeedback', {
            method: 'POST',
            body: formData,
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              // console.log(response);
              if (response.result === 1) {
                runModalShow(2);
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'registr':
        // console.log(roomes);

        const names = roomes.map((el) => el.name);
        const passId = names.indexOf('password');
        const passVal = roomes[passId].value;
        roomes?.map((inpItem, index) => {
          if (inpItem.typeMain === 'password' && inpItem.type === 'text') {
            roomes[index].type = 'password';
            roomes[index].toggleActive = !roomes[index].toggleActive;
          }
          switch (inpItem.name) {
            case 'password2':
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                (subActive = true);
              break;

            default:
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                (subActive = true);
              break;
          }
        });
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          // formData.append('recaptcha', 'test');

          const requestOptions = {
            method: 'POST',
            body: formData,
            redirect: 'follow',
          };
          fetch(API_URL + '/registerByEmail', requestOptions)
            .then((response) => response.json())
            .then((response) => {
              // console.log(response);
              if (response.result === 1) {


              	let regSource = localStorage.getItem('regSource', 0);

		        if (regSource == 0) {
    		  	  window.dataLayer.push({
	          		"event": "NutrilakEvent",
				    "eventCategory": "main",
					"eventAction": "submitForm",
					"eventLabel": "registration"
	          	  });
	            } else {
	              window.dataLayer.push({
	          		"event": "NutrilakEvent",
				    "eventCategory": "main",
					"eventAction": "submitForm",
					"eventLabel": "registration"+regSource
	          	  });
	            }

	            localStorage.setItem('regSource', 0);


                // window.location.replace('/');
                runModalShow(3, 'login');
                // setModalShow(3);
                // response.data.link ? submi'activate', response.data.link) : setModalShow(3);
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'login':
        roomes?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/authenticateByEmail', {
            method: 'POST',
            body: formData,
            redirect: 'follow',
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              // console.log(response);
              // console.log(response.data.avatar.options);
              if (response.result === 1) {
                localStorage.setItem('auth_key', response.data.auth_key);
                getProfile();
                window.location.href = '/cabinet';
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'forgonLogin':
        roomes?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validation(inpItem.type, inpItem.value, index) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          formData.append('recaptcha', 'test');
          fetch(API_URL + '/resendPassword', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              // console.log(response);
              if (response.result === true) {
                // setModalShow(3);
                runModalShow(3, 'login');
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'activate':
        subData = subData.split('confirm/');
        // console.log(subData[1]);
        if (!subActive) {
          formData.append('token', subData[1]);
          fetch(API_URL + '/activate', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              // console.log(response);
              if (response.result === true) {
                // console.log('activate send');
                // getProfile();
                localStorage.setItem('auth_key', response.data.auth_key);
                localStorage.setItem('isChosen', response.data.avatar.isChosen);
                localStorage.setItem('isUploaded', response.data.avatar.isUploaded);
                localStorage.setItem('profile', JSON.stringify(response.data));
                localStorage.setItem('avatar', JSON.stringify(response.data.avatar.options));
                window.location.replace('/#steps');
              } else {
                console.log('activate error');
                window.location.replace('/');
                console.log(response.error);
              }
            });
        }

        break;
      case 'close':
        window.localStorage.clear();
        window.location.replace('/');

        break;
      case 'updateProfile':
        roomes?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                inpItem.nameForm !== 'none' && formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/updateProfile', {
            method: 'POST',
            body: formData,
            redirect: 'follow',
            headers: {
              'x-auth-token': localStorage.getItem('auth_key'),
            },
          })
            .then((response) => response.json())
            .then((response) => {
              console.log(response);
              // console.log(response.data.avatar.options);
              if (response.result === 1) {
                getProfile();
                setModalShow(13);
                // window.location.href = '/cabinet';
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      default:
        console.log('sd');
        break;
    }
  };
  const getCodes = async (page) => {
    await fetch(API_URL + '/getCodes?page=' + page, {
      method: 'GET',
      headers: {
        'x-auth-token': localStorage.getItem('auth_key'),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.result === 1) {
          setCodes(response.data.rows);
          setPerPage(response.data?.paging?.total);
        }
      });
  };
  const clickMenu = () => {
    setNav(!nav);
    document.body.classList.toggle('openMenu');
  };
  const clickMenuClose = () => {
    setNav(false);
    document.body.classList.remove('openMenu');
  };
  const getProfile = () => {
    fetch(API_URL + '/getProfile', {
      method: 'GET',
      headers: {
        'x-auth-token': localStorage.getItem('auth_key'),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response && response !== 'undefined' && response.result === 1) {
          setProfile(response.data.profile);

          widgetParams.userUuid = response.data.profile.userUuid;
          window.qrWidget.init('apm-widget', widgetParams);
        }

        if (response.status === 401) {
          response.status === 401 && window.location.replace('/');
          window.localStorage.clear();
        }
      });
  };
  const btnUploadCheck = (cl) => {

    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== null &&
      localStorage.getItem('auth_key') !== undefined
    ) {
      return (
        <button
          id="apm-scan-qr2"
          className={cl}
          onClick={() => {
            document.getElementById('apm-scan-qr').click();
            // this.props.info5Showw();
          }}>
          Зарегистрировать чек
        </button>
      );
    } else {
      return (
        <button
          className={cl}
          onClick={() => {
            runModalShow(1, 'registr');
          }}>
          Зарегистрировать чек
        </button>
      );
    }
  };

  const prizeBall = (txt) => {
    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== null &&
      localStorage.getItem('auth_key') !== undefined
    ) {
      return (
      	<div
      	  className="prize-ball"
      	  onClick={() => {
            document.getElementById('apm-scan-qr').click();
          }} dangerouslySetInnerHTML={{__html: txt}}>
      	</div>
      );
    } else {
      return (
      	<div
      	  className="prize-ball"
      	  onClick={() => {
            runModalShow(1, 'registr');
          }} dangerouslySetInnerHTML={{__html: txt}}>
      	</div>
      );
    }
  };


  return (
    <div>
      <Header
        submit={submit}
        clickMenu={clickMenu}
        menu={menu}
        nav={nav}
        clickMenuClose={clickMenuClose}
        runModalShow={runModalShow}
        profile={profile}
      />
      <Switch>
        <Route
          key="main"
          exact
          path="/"
          render={(props) => (
            <Home
              {...props}
              btnUploadCheck={btnUploadCheck}
              prizeBall={prizeBall}
              API_URL={API_URL}
              runModalShow={runModalShow}
            />
          )}
        />
        <Route
          key="cabinet"
          path="/cabinet"
          render={(props) => (
            <Cabinet
              {...props}
              btnUploadCheck={btnUploadCheck}
              profile={profile}
              submit={submit}
              getCodes={getCodes}
              codes={codes}
              perPage={perPage}
              setPerPage={setPerPage}
              setCodes={setCodes}
              API_URL={API_URL}
              runModalShow={runModalShow}
            />
          )}
        />
        <Route path="*" component={Home} />
      </Switch>
      <Footer runModalShow={runModalShow} />
      <ModalsBlock
        modalShow={modalShow}
        modalClose={modalClose}
        runModalShow={runModalShow}
        submit={submit}
        modals={Modals}
        togglePass={togglePass}
        roomes={roomes}
        setModalShow={setModalShow}
        setRoomes={setRoomes}
        setValidation={setValidation}
        handleroom={handleroom}></ModalsBlock>
      <div id="apm-widget"></div>
    </div>
  );
}
